.FactionActivity {
  overflow-x: auto;
  max-height: calc(100vh - 200px);
  font-size: 14px;
}

.FactionActivity thead {
  position: sticky;
  top: 0;
  z-index: 10;
}

.FactionActivity tr {
  /*background-color:#222;
  border-bottom: #333 solid;*/
}

.FactionActivity td, .FactionActivity th {
  padding: 5px;
  background-color: #222;
  border: #333 solid 1px;
  text-align: center;
}

.FactionActivity tr td:first-child {
  position: sticky;
  left: 0;
  text-align: right;
}

.FactionActivity thead tr th:first-child {
  position: sticky;
  left: 0;
  text-align: right;
}