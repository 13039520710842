.Header {
  background-color: #333;
  width: 100%;
}

.Header__content {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
}

.Header__content>header {
  font-size: 40px;
  font-weight: 200;
  font-family: 'Montserrat', sans-serif;
}

.Header__stats {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 5px;
  margin-bottom: 5px;
}

.Header__logout {
  cursor: pointer;
  text-decoration: underline;
}