.LoginWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LoginWrapper__content>header {
  font-size: 40px;
  font-weight: 200;
  font-family: 'Montserrat', sans-serif;
}

.LoginWrapper__content {
  padding: 20px;
  background-color: #333;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoginWrapper__form {
  margin-top: 20px;
}

.LoginWrapper__form>input {
  margin-left: 10px;
  background-color: #222;
  border: none;
  appearance: none;
  color: #eee;
  padding: 10px;
}

.LoginWrapper__form>button {
  position: relative;
  margin-left: 10px;
  padding: 10px;
  background-color: #222;
  border: none;
  appearance: none;
  color: #eee;
  margin-right: 18px;
  cursor: pointer;
}

.LoginWrapper__form>button::after {
  content: "";
  position: absolute;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-right: 18px solid transparent;
  border-left: 18px solid #222;
  right: -36px;
  top: 0px;
  cursor: pointer;
}

.LoginWrapper__error {
  margin-top: 10px;
  color: #c00;
}

.LoginWrapper__key-link {
  margin-top: 10px;
}