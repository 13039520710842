.Faction {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Faction__content {
  width: fit-content;
  max-width: 90%;
  padding: 20px;
  background-color: #333;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.Faction__content:has(.FactionStatus) {
  width: 90%;
}

@media (max-width:1000px) {
  .Faction__content {
    max-width: 95%;
  }
}