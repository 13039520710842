@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&family=Roboto&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222;
  color: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #eee;
}

a:visited {
  color: #eee;
}

a:hover {
  color: #bbb;
}