.User {
  display: flex;
  align-items: center;
}

.User__data {
  flex-grow: 1;
}
/*
.User__bars {
  flex-grow: 1;
}
*/
.User__activity-bars, .User__state-bars {
  display: flex;
}

.User__name {
  width: 150px;
}

.User__expand {
  width: 20px;
  cursor: pointer;
}