.FactionNav {
  width: 100%;
}

.FactionNav__list {
  display: flex;
  justify-content: space-evenly;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
}

.FactionNav__list>li {
  list-style-type: none;
}

.FactionNav__list>li>a {
  color: #eee;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.FactionNav__list>li>a:hover {
  color: #fba607;
}

.FactionNav__list>li>a.active {
  color: #fba607;
  font-weight: 700;
}