.StateBar {
  height: 10px;
}

.StateBar--okay {
  background-color: rgb(93, 143, 24);
}

.StateBar--traveling {
  background-color: rgb(45, 157, 172);
}

.StateBar--abroad {
  background-color: rgb(55, 98, 163);
}

.StateBar--hospital {
  /*background-color: rgb(255, 135, 135);*/
  background-color: #a0101a;
}