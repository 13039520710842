.FactionStats {
  overflow-x: auto;
  max-height: calc(100vh - 200px);
}

.FactionStats thead {
  position: sticky;
  top: 0;
  z-index: 10;
}

.FactionStats tr {
  /*background-color:#222;
  border-bottom: #333 solid;*/
}

.FactionStats td, .FactionStats th {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #222;
  border: #333 solid 1px;
  text-align: center;
}

.FactionStats tr td:first-child {
  position: sticky;
  left: 0;
  text-align: right;
}

.FactionStats thead tr th:first-child {
  position: sticky;
  left: 0;
  text-align: right;
}