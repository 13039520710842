.ActivityBar {
  height: 5px;
}

.ActivityBar--Online {
  background-color: green;
}

.ActivityBar--Idle {
  background-color: orange;
}

.ActivityBar--Offline {
  background-color: lightgrey;
}